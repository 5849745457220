
















































import { Component, Mixins } from 'vue-property-decorator';
import ElePropertiesMixins from './mixins/ElePropertiesMixins';

@Component({
  name: 'DateConfig',
  components: {}
})
export default class DateConfig extends Mixins(ElePropertiesMixins) {
  dateTimeFormat: any = {
    date: 'yyyy-MM-dd',
    month: 'yyyy-MM',
    year: 'yyyy',
    datetime: 'yyyy-MM-dd HH:mm:ss',
    daterange: 'yyyy-MM-dd',
    monthrange: 'yyyy-MM',
    datetimerange: 'yyyy-MM-dd HH:mm:ss'
  };

  dateTypeOptions: any[] = [
    {
      label: '年(year)',
      value: 'year'
    },
    {
      label: '月(month)',
      value: 'month'
    },
    {
      label: '日(date)',
      value: 'date'
    },
    {
      label: '日期时间(datetime)',
      value: 'datetime'
    }
  ];
  handlerFormatChange(val) {
    this.widget.properties.format = this.dateTimeFormat[val];
  }
}
